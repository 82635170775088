<template>
  <section id="error_404">
    <h1>{{ $t('404.title') }}</h1>
    <img :src="require('../../assets/img/404.svg')"
         alt="Illustration montrant une lune en train de dormir avec des 4 autour pour représenter le code 404 error not found"
    />
    <router-link :to="url">
      <p>{{ $t('404.text') }}</p>
    </router-link>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Error404',

  computed: {
    ...mapGetters([
      'userIsConnected',
    ]),
    url () {
      return this.$router.resolve({ name: this.userIsConnected ? 'Dashboard' : 'Login' }).href
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

#error_404 {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 90%;

    @media all and (min-width: $s) {
      width: 80%;
    }

    @media all and (min-width: $m) {
      width: 70%;
    }
  }

  a {
    background-color: $whiteTransparent;
    width: 100%;
    text-align: center;
    font-size: 20px;
    border-radius: 5px;
    padding: 15px;

    p {
      margin: 0;
    }
  }
}
</style>
